/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    pre: "pre",
    code: "code",
    strong: "strong",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "scroll-indicator",
    style: {
      position: "relative"
    }
  }, "Scroll Indicator", React.createElement(_components.a, {
    href: "#scroll-indicator",
    "aria-label": "scroll indicator permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Scroll indicator, the shadow, provides an extra visual cue that content exists below or above the fold."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "customRender: true\n---\nconst loremIpsum =\n\t'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporincididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.';\n\nrender (\n\t<Stack>\n\t\t<Scroll height=\"100px\" className=\"m-r-3\" scrollIndicator>\n\t\t\t<BodyText>With scroll indicator. {loremIpsum}</BodyText>\n\t\t</Scroll>\n\t\t<Scroll height=\"100px\" className=\"m-r-3\">\n\t\t\t<BodyText>{loremIpsum}</BodyText>\n\t\t</Scroll>\n\t</Stack>\n)\n")), "\n", React.createElement(_components.h1, {
    id: "size",
    style: {
      position: "relative"
    }
  }, "Size", React.createElement(_components.a, {
    href: "#size",
    "aria-label": "size permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Maximum width and height may be set explicitly via props, as in example above. Size also may be controlled by external container."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "customRender: true\n---\nconst loremIpsum =\n\t'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporincididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.';\n\nrender (\n\t<Card padding=\"none\" light className=\"of-hidden\">\n\t\t<Card.Section style={{ height: '100px' }}>\n\t\t\t<Scroll scrollIndicator>\n\t\t\t\t<div className=\"m-x-2 m-y-2\">\n\t\t\t\t\t<BodyText>\n\t\t\t\t\t\tHeight is controlled by container.\n\t\t\t\t\t</BodyText>\n\t\t\t\t\t<BodyText className=\"m-t-1\">{loremIpsum}</BodyText>\n\t\t\t\t\t<BodyText className=\"m-t-1\">{loremIpsum}</BodyText>\n\t\t\t\t</div>\n\t\t\t</Scroll>\n\t\t</Card.Section>\n\t</Card>\n);\n")), "\n", React.createElement(_components.h1, {
    id: "horizontal",
    style: {
      position: "relative"
    }
  }, "Horizontal", React.createElement(_components.a, {
    href: "#horizontal",
    "aria-label": "horizontal permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "If content goes further rigth side of the Scroll, it may reflect such behavior."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={{}}>\n\t{([state, setState]) => {\n\t\tconst names =\n\t\t\t['Ben Ho', 'Matt Felton', 'James Coyle', 'Sergey Ya', 'Ara Mah', 'Doctor Rex', 'Alex Star', 'Trent Walton', 'Crazy Horse', 'Greg High', 'John Puy', 'Elen Lupton', 'Karl Wisley', 'Pol Obrian',];\n\n\t\treturn (\n\t\t\t<Card light className=\"of-hidden\">\n\t\t\t\t<Card.Section className=\"h3\">Participants</Card.Section>\n\t\t\t\t<Card.Section className=\"p-0\">\n\t\t\t\t\t<Scroll horizontal=\"scroll\" scrollIndicator>\n\t\t\t\t\t\t<div className=\"m-x-2 m-y-2 d-f\">\n\t\t\t\t\t\t\t{names.map((name, nameIndex) => (\n\t\t\t\t\t\t\t\t<div className=\"m-r-2\" key={`name${nameIndex}`}>\n\t\t\t\t\t\t\t\t\t<Avatar\n\t\t\t\t\t\t\t\t\t\tautoColor\n\t\t\t\t\t\t\t\t\t\tname={name}\n\t\t\t\t\t\t\t\t\t\tsize=\"l\"\n\t\t\t\t\t\t\t\t\t/>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t))}\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</Scroll>\n\t\t\t\t</Card.Section>\n\t\t\t</Card>\n\t\t);\n\t}}\n</State>\n")), "\n", React.createElement(_components.h1, {
    id: "hidden-scrollbar",
    style: {
      position: "relative"
    }
  }, "Hidden Scrollbar", React.createElement(_components.a, {
    href: "#hidden-scrollbar",
    "aria-label": "hidden scrollbar permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "User is capable to scroll content even without ", React.createElement(_components.strong, null, "visible scrollbar"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={{}}>\n\t{([state, setState]) => {\n\t\tconst loremIpsum =\n\t\t\t'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporincididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.';\n\n\t\treturn (\n\t\t\t<div className=\"m-t-8\">\n\t\t\t\t<Popover\n\t\t\t\t\tdirection=\"tr\"\n\t\t\t\t\topen\n\t\t\t\t\ttrigger={<Button>Popover</Button>}\n\t\t\t\t\tpadding={null}\n\t\t\t\t>\n\t\t\t\t\t<Scroll height=\"118px\" scrollbar=\"hidden\" scrollIndicator>\n\t\t\t\t\t\t<div className=\"p-x-3 p-y-2\">\n\t\t\t\t\t\t\t<BodyText>{loremIpsum}</BodyText>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</Scroll>\n\t\t\t\t</Popover>\n\t\t\t</div>\n\t\t);\n\t}}\n</State>\n")), "\n", React.createElement(_components.h1, {
    id: "use-cases",
    style: {
      position: "relative"
    }
  }, "Use Cases", React.createElement(_components.a, {
    href: "#use-cases",
    "aria-label": "use cases permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Scroll component intended to be used in:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "overlay components, like Modal, Drawer, Popover, Toast, Takeover, ActionMenu, etc., where scrollable behavior might be unexpected"), "\n", React.createElement(_components.li, null, "fixed-size components, like Card, Tab, Sidebar, where extra visual hint come in handy"), "\n", React.createElement(_components.li, null, "Select component in case of too long list of options, which makes scrollbar unusable"), "\n"), "\n", React.createElement("br"), "\n", React.createElement(_components.h1, {
    id: "examples",
    style: {
      position: "relative"
    }
  }, "Examples", React.createElement(_components.a, {
    href: "#examples",
    "aria-label": "examples permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "frame: true\nnoPadding: true\nheight: 342\n---\n<State initial={{}}>\n\t{([state, setState]) => {\n\t\tconst loremIpsum =\n\t\t\t'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporincididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.';\n\n\t\treturn (\n\t\t\t<Modal\n\t\t\t\ttitle=\"Modal Title\"\n\t\t\t\tclosable\n\t\t\t\topen\n\t\t\t\tonClose={() => {}}\n\t\t\t\tfooter={\n\t\t\t\t\t<ButtonGroup>\n\t\t\t\t\t\t<Button>Cancel</Button>\n\t\t\t\t\t\t<Button primary>Save</Button>\n\t\t\t\t\t</ButtonGroup>\n\t\t\t\t}\n\t\t\t\tportal={false}\n\t\t\t\tsize={Modal.Sizes.S}\n\t\t\t>\n\t\t\t\t<div\n\t\t\t\t\tstyle={{\n\t\t\t\t\t\tmargin: `-${tokens.spacing3} -${tokens.spacing4}`,\n\t\t\t\t\t\theight: `calc(100% + ${tokens.spacing5})`,\n\t\t\t\t\t}}\n\t\t\t\t>\n\t\t\t\t\t<Scroll scrollIndicator>\n\t\t\t\t\t\t<div className=\"p-3\">\n\t\t\t\t\t\t\t<BodyText>{loremIpsum}</BodyText>\n\t\t\t\t\t\t\t<BodyText className=\"m-t-2\">{loremIpsum}</BodyText>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</Scroll>\n\t\t\t\t</div>\n\t\t\t</Modal>\n\t\t);\n\t}}\n</State>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "frame: true\nnoPadding: true\nheight: 342\n---\n<State initial={{}}>\n\t{([state, setState]) => {\n\t\tconst loremIpsum =\n\t\t\t'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporincididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.';\n\n\t\treturn (\n\t\t\t<Drawer\n\t\t\t\topen\n\t\t\t\theader=\"Drawer\"\n\t\t\t\tonClose={() => {}}\n\t\t\t\tportal={false}\n\t\t\t\tfooter={\n\t\t\t\t\t<ButtonGroup>\n\t\t\t\t\t\t<Button>Cancel</Button>\n\t\t\t\t\t\t<Button primary>Save</Button>\n\t\t\t\t\t</ButtonGroup>\n\t\t\t\t}\n\t\t\t\t>\n\t\t\t\t<div\n\t\t\t\t\tstyle={{\n\t\t\t\t\t\tmargin: `-${tokens.spacing3}`,\n\t\t\t\t\t\theight: `calc(100% + ${tokens.spacing5})`,\n\t\t\t\t\t}}\n\t\t\t\t>\n\t\t\t\t\t<Scroll scrollIndicator>\n\t\t\t\t\t\t<div className=\"p-3\">\n\t\t\t\t\t\t\t<BodyText>{loremIpsum}</BodyText>\n\t\t\t\t\t\t\t<BodyText className=\"m-t-2\">{loremIpsum}</BodyText>\n\t\t\t\t\t\t\t<BodyText className=\"m-t-2\">{loremIpsum}</BodyText>\n\t\t\t\t\t\t\t<BodyText className=\"m-t-2\">{loremIpsum}</BodyText>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</Scroll>\n\t\t\t\t</div>\n\t\t\t</Drawer>\n\t\t);\n\t}}\n</State>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "frame: true\nnoPadding: true\nheight: 342\n---\n<State initial={{}}>\n\t{([state, setState]) => {\n\t\tconst loremIpsum =\n\t\t\t'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporincididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.';\n\n\t\treturn (\n\t\t\t<Takeover\n\t\t\t\tbackLabel=\"Previous Page\"\n\t\t\t\ttitle=\"Takeover Title\"\n\t\t\t\tfooter={\n\t\t\t\t\t<ButtonGroup>\n\t\t\t\t\t\t<Button>Cancel</Button>\n\t\t\t\t\t\t<Button primary>Save</Button>\n\t\t\t\t\t</ButtonGroup>\n\t\t\t\t}\n\t\t\t\tfooterAlign=\"right\"\n\t\t\t\tonClose={() => {}}\n\t\t\t\tportal={false}\n\t\t\t>\n\t\t\t\t<Takeover.Section\n\t\t\t\t\tstyle={{ padding: '0px', height: '100%' }}\n\t\t\t\t>\n\t\t\t\t\t<Scroll scrollIndicator>\n\t\t\t\t\t\t<div className=\"p-3\">\n\t\t\t\t\t\t\t<BodyText>{loremIpsum}</BodyText>\n\t\t\t\t\t\t\t<BodyText className=\"m-t-2\">\n\t\t\t\t\t\t\t\t{loremIpsum}\n\t\t\t\t\t\t\t</BodyText>\n\t\t\t\t\t\t\t<BodyText className=\"m-t-2\">\n\t\t\t\t\t\t\t\t{loremIpsum}\n\t\t\t\t\t\t\t</BodyText>\n\t\t\t\t\t\t\t<BodyText className=\"m-t-2\">\n\t\t\t\t\t\t\t\t{loremIpsum}\n\t\t\t\t\t\t\t</BodyText>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</Scroll>\n\t\t\t\t</Takeover.Section>\n\t\t\t</Takeover>\n\t\t);\n\t}}\n</State>\n")), "\n", React.createElement("br"), "\n", React.createElement(_components.h1, {
    id: "related-components",
    style: {
      position: "relative"
    }
  }, "Related Components", React.createElement(_components.a, {
    href: "#related-components",
    "aria-label": "related components permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/collapsible/"
  }, "Collapsible"))), "\n"), "\n", React.createElement("br"), "\n", React.createElement(_components.h1, {
    id: "importing",
    style: {
      position: "relative"
    }
  }, "Importing", React.createElement(_components.a, {
    href: "#importing",
    "aria-label": "importing permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "code: true\n---\nimport { Scroll } from '@servicetitan/design-system';\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
